import Skeleton from "@material-ui/lab/Skeleton";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import { useContext } from "react";
import { useCompanyLogo } from "../hooks";
import { useAccountList, useInstitutionList } from "../hooks/account.hooks";
import "../scss/linked_account.scss";
import JointAccountContext from "../context/joint-account-context";

const LinkedAccount = ({
  isSelected = false,
  accountId = "",
  logo = "",
  name = "",
  value = 0,
  ownerName = "",
  cursor = false,
  shouldHideValue = false,
  onClick = () => {},
}) => {
  const { darkLogo } = useCompanyLogo();
  const { isLoading, isIdle } = useAccountList();
  const { isLoading: isLoadingInstitutionList } = useInstitutionList();
  const { selectedJointAccount = "" } = useContext(JointAccountContext);
  const isGettingAccountList = isLoading || isIdle;

  const handleClickAccount = (e) => {
    if (cursor) {
      onClick(accountId);
    } else {
      e.preventDefault();
    }
  };

  const renderValue = () => {
    if (isGettingAccountList) {
      return (
        <Skeleton animation="wave" variant="text" width="50%" height={20} />
      );
    }
    if (shouldHideValue) {
      return undefined;
    }
    return (
      <NumberFormat
        value={value}
        displayType="text"
        thousandSeparator
        prefix="$"
      />
    );
  };

  return (
    <div
      className={`la-container ${cursor && "is-cursor"} ${
        isSelected && cursor ? "selected" : ""
      }`}
      onClick={handleClickAccount}
      id={accountId}
    >
      <div className="linked-account-container">
        <div className="linked-account-wrapper">
          <div className="la-logo">
            {isGettingAccountList || isLoadingInstitutionList ? (
              <Skeleton
                animation="wave"
                variant="circle"
                width={50}
                height={50}
              />
            ) : (
              (logo || darkLogo) && <img src={logo || darkLogo} alt={name} />
            )}
          </div>

          <div className="linked-account-item-value">
            {Boolean(ownerName) && selectedJointAccount && (
              <div className="linked-account-owner">
                {ownerName}&apos;s Account
              </div>
            )}
            <div>
              {isGettingAccountList ? (
                <Skeleton
                  animation="wave"
                  variant="text"
                  width="70%"
                  height={20}
                />
              ) : (
                name
              )}
            </div>
            {renderValue()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LinkedAccount;

LinkedAccount.propTypes = {
  isSelected: PropTypes.bool,
  accountId: PropTypes.string,
  logo: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.number,
  shouldHideValue: PropTypes.bool,
  cursor: PropTypes.bool,
  onClick: PropTypes.func,
};
