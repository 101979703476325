import { Backdrop, CircularProgress } from "@material-ui/core";
import classnames from "classnames";
import React from "react";
import "./external-accounts-connect-ui-dialog.scss";

function ExternalAccountsConnectUiDialog({
  className,
  open,
  id,
  children,
  isLoading = true,
}) {
  return (
    <Backdrop
      className={classnames("external-accounts-connect-ui-dialog", className)}
      open={open}
    >
      <div id={id} className="external-accounts-connect-ui-dialog__container">
        {isLoading && (
          <div className="external-accounts-connect-ui-dialog__loading-indicator-container">
            <CircularProgress />
          </div>
        )}
        {children}
      </div>
    </Backdrop>
  );
}

export default ExternalAccountsConnectUiDialog;
